export const MapConfig = {
  zoom: 2,
  center: [12, 64],

  inernwebMapExtent: {
    xmin: -915020.1799341175,
    ymin: 6373074.350009031,
    xmax: 1564024.778155799,
    ymax: 7833407.937342872,
    spatialReference: {
      wkid: 32633,
    },
  },

  kundewebMapExtent: {
    xmin: -392943.35479620297,
    ymin: 6363722.055130746,
    xmax: 1137833.0400899202,
    ymax: 7918882.498784967,
    spatialReference: {
      wkid: 32633,
    },
  },

  vectorTileLayerUrl:
    "https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheBasis_WGS84/VectorTileServer/",

  kspuLayerUrl:
    "https://pumagisportal.posten.no/arcgis/sharing/servers/966524d8533e4c39af18689f25ac8f95/rest/services/KSPU/MapServer",

  kundewebLayerUrl:
    "https://pumagisportal.posten.no/arcgis/sharing/servers/b020e77937874ce7b92499effc97bb12/rest/services/PumaKundeweb/MapServer",

  geoKodingUrl:
    "https://pumagisportal.posten.no/arcgis/sharing/servers/af7acdd24e4f4dd588064ba08ac3303b/rest/services/GeoKoding/GeocodeServer",

  geoSokUrl:
    "https://pumagisportal.posten.no/arcgis/sharing/servers/ed13b751dedc41d9a2df67fb7986bc12/rest/services/Geosok/GeocodeServer",

  driveTimeAnalysisUrl:
    "https://pumagisportal.posten.no/arcgis/sharing/servers/ec69ac6425cb4e1a96f945684d57c9da/rest/services/DriveTime/GPServer",

  oldUtvalgGeometryUrl:
    "https://pumagisportal.posten.no/arcgis/sharing/servers/c19417b057cc41039b1b87aa28f5ff2f/rest/services/OldUtvalgGeometry/MapServer/0",

  budruterOutField: [
    "reolnavn",
    "reolnr",
    "beskrivelse",
    "kommentar",
    "reol_id",
    "kommuneid",
    "kommune",
    "fylkeid",
    "fylke",
    "teamnr",
    "teamnavn",
    "postnr",
    "poststed",
    "segment",
    "hh",
    "hh_res",
    "gb",
    "gb_res",
    "er",
    "er_res",
    "vh",
    "p_hh_u_res",
    "np_hh_u_res",
    "p_vh_u_res",
    "np_vh_u_res",
    "prissone",
    "reoltype",
    "pbkontnavn",
    "prsnr",
    "prsnavn",
    "prsbeskrivelse",
    "rutedistfreq",
    "sondagflag",
  ],
};
